import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import Button from "./Button";
import clsx from "../utils/clsx";

const options = {
  "signed": {
    icon: CheckCircleIcon,
    color: "text-emerald-500",
    title: "Event signed",
    description: "Your digital cryptographic signature on the documents has been captured and the event has been signed. You will receive an email copy of the documents.",
    actions: [
      {
        text: "Download",
        variant: "primary",
        onClick: () => console.log("Download")
      },
      {
        text: "Contact provider",
        variant: "slate",
        onClick: () => console.log("Provider")
      }
    ]
  },
  "canceled": {
    icon: XCircleIcon,
    color: "text-rose-500",
    title: "Event canceled",
    description: "The event has been canceled by the providers and the documents can no longer be signed. Please contact the provider for assistance.",
    actions: [
      {
        text: "Contact provider",
        variant: "slate",
        onClick: () => console.log("Provider")
      }
    ]
  },
  "review": {
    icon: ExclamationCircleIcon,
    color: "text-amber-500",
    title: "Event under review",
    description: "Your response is under review by the provider. Your digital cryptographic signature on the documents will be captured upon provider's approval. Please contact the provider for assistance.",
    actions: [
      {
        text: "Contact provider",
        variant: "slate",
        onClick: () => console.log("Provider")
      }
    ]
  }
}

const Status = ({ status }) => {
  const data = options[status];

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center md:max-w-3xl mx-auto">
        <data.icon className={clsx(data.color, "w-24 h-24 mx-auto")} />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{data.title}</h1>
        <p className="mt-6 text-base text-gray-600">{data.description}</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          {data.actions.map(action =>
            <Button text={action.text} variant={action.variant} onClick={action.onClick} />
          )}
        </div>
      </div>
    </main>
  )
}

export default Status