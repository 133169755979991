import { useState, useEffect } from "react";
import axios from 'axios';

const useEvent = (accountId, apiKey, eventId) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvent = async () => {
      let endpoint = `https://${accountId}.orionexus.io/sdk/events/${eventId}/`;

      try {
        const response = await axios.get(endpoint, {
          headers: {
            'Token': apiKey,
          }
        });
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (accountId && eventId) {
      fetchEvent();
    }
  }, [accountId, apiKey, eventId]);

  const updateEventStatus = async (status) => {
    let endpoint = `https://${accountId}.orionexus.io/sdk/events/${eventId}/`;

    try {
      const response = await axios.patch(endpoint, { status: status }, {
        headers: {
          'Token': apiKey,
        }
      });
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const sign = async (geolocation) => {
    let endpoint = `https://${accountId}.orionexus.io/sdk/events/${eventId}/sign/`;

    try {
      const response = await axios.patch(endpoint, {
        latitude: geolocation?.latitude ? geolocation.latitude : 0,
        longitude: geolocation?.longitude ? geolocation.longitude : 0,
      }, {
        headers: {
          'Token': apiKey,
        }
      });
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, updateEventStatus, sign };
}

export default useEvent;
