import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const NotFound = () => {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center md:max-w-2xl mx-auto">
        <ExclamationCircleIcon className="w-24 h-24 text-slate-500 mx-auto" />
        <p className="text-base font-semibold text-gray-500">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a href="#" className="text-sm font-semibold text-gray-900">
            Contact provider <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  )
}

export default NotFound