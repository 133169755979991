import React, { useEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  ChevronDownIcon,
} from '@heroicons/react/20/solid'
import clsx from "../utils/clsx";

const options = {
  size: {
    xs: "rounded px-2 py-1 text-xs",
    sm: "rounded px-2 py-1 text-sm",
    md: "rounded-md px-2.5 py-1.5 text-sm",
    lg: "rounded-md px-3 py-2 text-sm",
    xl: "rounded-md px-3.5 py-2.5 text-sm",
  },
  variant: {
    primary: "text-white bg-slate-800 border border-slate-800 hover:bg-slate-700",
    slate: "bg-slate-50 border border-gray-200 hover:bg-slate-100 focus-visible:outline-gray-200",
    danger: "text-white bg-rose-700 border border-rose-700 hover:bg-rose-800 focus-visible:outline-rose-700",
    dangerOutline: "text-rose-700 bg-white border border-rose-700 hover:bg-rose-50 focus-visible:outline-rose-700",
    transparent: "border border-transparent hover:bg-slate-100 focus-visible:outline-slate-800",
    clear: "hover:bg-slate-100 border border-white focus-visible:outline-white ",
    disabled: "text-gray-400 bg-gray-200 border border-transparent",
  },
}

const Item = ({ item }) => {
  return (
    <MenuItem>
      <div
        onClick={item.onClick}
        className="group flex items-center cursor-pointer px-3 py-1.5 text-xs text-gray-500 data-[focus]:bg-slate-100"
      >
        {item.icon &&
          <item.icon aria-hidden="true" className="mr-3 h-4 w-4 text-gray-800 group-hover:text-gray-900" />
        }
        {item.text &&
          <span>{item.text}</span>
        }
      </div>
    </MenuItem>
  )
}

const DropDownButton = ({
  items = [],
  text,
  icon,
  size = "md",
  variant = "primary",
  full = false,
  disabled = false,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton
          className={clsx(
            options.size[size],
            full && "w-full",
            disabled ? options.variant["disabled"] : options.variant[variant],
            "group font-medium whitespace-nowrap text-center sm:text-left gap-1.5 inline-flex items-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          )}
        >
          <div
            className={clsx(
              full && "w-full",
              "flex gap-2 justify-center items-center",
            )}
          >
            {icon && icon}
            {text && text}
          </div>
          <ChevronDownIcon aria-hidden="true" className="h-4 w-4 text-gray-300" />
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute end-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {items.map((group, groupInd) => (
          <div key={groupInd} className="py-1" >
            {group.map((item, index) => (
              <MenuItem key={index}>
                <Item item={item} />
              </MenuItem>
            ))}
          </div>
        ))}
      </MenuItems>
    </Menu >
  )
}

export default DropDownButton