import { BrowserRouter, Routes, Route } from "react-router-dom";
import Event from "./components/Event";
import NotFound from "./components/NotFound";
import './index.css';


const App = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<div>Home Page</div>} />
        <Route path="/:accountId/:eventId" element={<Event />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
