import React, { useState } from "react";
import clsx from "../utils/clsx";

const Input = ({
  label,
  id,
  name,
  placeholder,
  value,
  help,
  uneditable = false,
  optional = false,
  onChange = null,
  errors,
  type = "text",
  width = "w-full",
  disabled = false,
  prefix,
  suffix,
}) => {
  const [data, setData] = useState(value);

  return (
    <div className="space-y-1.5 font-normal">
      <label htmlFor={id} className="block text-xs text-gray-900">
        <div className="flex gap-2 justify-between">
          <span className="flex gap-2 font-semibold">
            <div>
              {label}
            </div>
          </span>
          {optional &&
            <span className="text-gray-500 font-light">
              Optional
            </span>
          }
        </div>
      </label>
      {!uneditable &&
        <div className="mt-2 flex rounded-md">
          {prefix &&
            <span className="inline-flex whitespace-nowrap text-xs items-center rounded-l-md border-t border-b border-l border-gray-200 px-2 text-gray-500">
              {prefix}
            </span>
          }
          <input
            id={id}
            name={name}
            type={type}
            disabled={disabled}
            defaultValue={value}
            onChange={onChange}
            placeholder={placeholder}
            className={clsx(
              `block border-0 ring-1 ring-inset ring-gray-200 text-xs focus:ring-2 focus:ring-inset focus:ring-slate-700 focus:outline-none`,
              `${prefix && suffix && 'rounded-none'} ${prefix && !suffix && 'rounded-r-md'} ${!prefix && suffix && 'rounded-l-md'} ${!prefix && !suffix && 'rounded-md'}`,
              errors ? "border-red-500" : "border-gray-200",
              width,
              disabled ? "bg-gray-100 text-gray-500" : ''
            )}
          />
          {suffix &&
            <span className="inline-flex whitespace-nowrap items-center text-xs rounded-r-md border-1 border-t border-b border-r border-gray-200 bg-gray-100 px-2 text-gray-500">
              {suffix}
            </span>
          }
        </div>

      }
      {uneditable &&
        <div id="" className={`block border-gray-200 rounded-md text-xs py-2`}>{value}</div>
      }
      {help &&
        <div className="text-gray-500 text-xs">{help}</div>
      }
    </div>
  )
}

export default Input;