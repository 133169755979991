import React from "react";

const ButtonGroup = ({ children }) => {
  return (
    <div className="flex flex-col sm:flex-row items-center gap-1.5">
      {children}
    </div>
  )
}

export default ButtonGroup;