import { useState, useEffect } from 'react';

const useMultiSteps = (initialSteps) => {
  const [steps, setSteps] = useState(initialSteps)
  const [index, setIndex] = useState(0);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const step = steps[index]

  useEffect(() => {
    validate()
  }, [data])

  const next = () => {
    setIndex((s) => Math.min(s + 1, steps.length - 1));
  };

  const previous = () => {
    setIndex((s) => Math.max(s - 1, 0));
  };

  const goTo = (step) => {
    setIndex(Math.min(Math.max(step - 1, 0), steps.length - 1));
  };

  const update = (data) => {
    setData((d) => ({ ...d, ...data }));
  };

  const validate = () => {
    setSteps((s) =>
      s.map((ss) =>
        ss.number === step.number ? { ...ss, passed: step.validate(data) } : ss
      )
    );
  };

  return {
    steps,
    index,
    step,
    data,
    errors,
    next,
    previous,
    goTo,
    update,
    validate,
    isLastStep: index === steps.length - 1,
    isFirstStep: index === 0,
  };
};

export default useMultiSteps;
