import React from "react";
import { Transition, TransitionChild, Description, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import Background from "./Background";
import Button from "./Button";

const Modal = ({ isOpen, closeModal, title, description, buttons, children, stat = false }) => {

  if (isOpen) {
    return (
      <Dialog open={isOpen} onClose={stat ? () => { } : closeModal} className="relative z-50" static={stat}>
        <Background>
          <div className="flex min-h-full items-end justify-center text-center sm:items-center p-3">
            <DialogPanel className="relative space-y-3 overflow-hidden w-full sm:max-w-2xl bg-white transform transition-all text-left rounded-lg shadow-xl px-4 pb-4 pt-5 sm:my-8 sm:p-6">
              <div>
                {title && <DialogTitle className="text-base font-bold">{title}</DialogTitle>}
                {description && <Description className="text-xs text-gray-500">{description}</Description>}
              </div>
              <div>
                {children}
              </div>
              {buttons &&
                <div className="flex gap-1.5 justify-end">
                  {buttons?.cancel &&
                    <Button
                      text={buttons?.cancel?.text}
                      variant="transparent"
                      size="xs"
                      onClick={buttons?.cancel?.onClick}
                    />
                  }
                  {buttons?.action &&
                    <Button
                      text={buttons?.action?.text}
                      variant={buttons?.action?.variant || "primary"}
                      size="xs"
                      onClick={buttons?.action?.onClick}
                    />
                  }
                </div>
              }
            </DialogPanel>
          </div>
        </Background>
      </Dialog>
    );
  }
};

export default Modal;
