import React, { useState, useEffect } from "react";
import useModal from "../hooks/useModal";
import Modal from "./Modal";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Input from "./Input";
import useMultiSteps from "../hooks/useMutliSteps";

const Verification = ({ steps, sign }) => {
  const [count, setCount] = useState(30);

  useEffect(() => {
    if (count === 0) return;

    const intervalId = setInterval(() => {
      setCount(count - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [count]);

  return (
    <div className="space-y-3">
      <Input
        label="One-time password"
        id="otp"
        name="otp"
        onChange={(e) => steps.update({ otp: e.target.value.trim() })}
        value={steps.data.otp}
      />
      <ButtonGroup>
        <Button
          text="Verify OTP"
          variant="primary"
          onClick={sign}
          disabled={!steps.step.passed}
          full
        />
        <Button
          text={`Resend OTP ${count === 0 ? '' : `in ${count} seconds`}`}
          variant="slate"
          disabled={count !== 0}
          onClick={() => setCount(30)}
          full
        />
      </ButtonGroup>
      <div className="text-xs text-gray-500">Trouble with authentication? <button className="text-slate-900">Use a different method</button></div>
    </div>
  )
}

const Method = ({ steps }) => {
  return (
    <div className="space-y-3">
      <Input
        label="National ID"
        name="national-id"
        onChange={(e) => steps.update({ id: e.target.value.trim() })}
        value={steps.data.id}
      />
      <ButtonGroup>
        <Button
          text="Send OTP"
          variant="primary"
          onClick={steps.next}
          disabled={!steps.step.passed}
          full
        />
      </ButtonGroup>
      <div className="text-xs text-gray-500">Trouble with authentication? <button className="text-slate-900">Use a different method</button></div>
    </div>
  )
}

const Auth = ({ modal, sign}) => {
  const steps = useMultiSteps([
    {
      number: 1,
      title: 'Authenticate your identity',
      subtitle: 'Enter your national identification number to receive a one-time password on your registered phone number.',
      passed: false,
      component: (props) => (<Method {...props} />),
      validate: (data) => {
        if (!data.id) {
          return false
        }
        if (data.id.length !== 10) {
          return false
        }
        return true;
      },
    },
    {
      number: 2,
      title: "Verify your OTP",
      description: "Enter your national identification number to receive a one-time password on your registered phone number.",
      passed: false,
      component: (props) => (<Verification {...props} />),
      validate: (data) => {
        if (!data.otp) {
          return false
        }
        if (data.otp.length !== 6) {
          return false
        }
        return true;
      },
    },
  ])

  return (
    <Modal
      stat
      title={steps.step.title}
      description={steps.step.description}
      isOpen={modal.isOpen}
      closeModal={modal.closeModal}
      buttons={{
        cancel: {
          text: "Cancel",
          onClick: () => modal.closeModal()
        },
      }}
    >
      {steps.step.component({ steps, sign })}
    </Modal>
  )
}

export default Auth;