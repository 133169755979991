import React, { useCallback, useEffect, useRef, useState } from 'react';

const STATUS = {
  UPCOMING: 'upcoming',
  CURRENT: 'current',
  COMPLETE: 'complete',
};

const useDocuments = (context) => {
  const readingRef = useRef(null);
  const [index, setIndex] = useState(0);
  const [allDocumentsCompleted, setAllDocumentsCompleted] = useState(false);
  const [documents, setDocuments] = useState(() =>
    context.map((document, index) => ({
      context: document,
      index: index,
      status: index === 0 ? STATUS.CURRENT : STATUS.UPCOMING,
      read: false,
      onClick: () => setIndex(index),
    }))
  );

  const updateStatus = (currentIndex = index) => {
    setDocuments((prevDocs) =>
      prevDocs.map((doc, idx) => {
        if (idx < currentIndex) {
          return { ...doc, status: 'complete', read: true };
        } else if (idx === currentIndex) {
          return { ...doc, status: 'current' };
        } else {
          return { ...doc, status: 'upcoming' };
        }
      })
    );
  };

  const nextDocument = () => {
    if (index === documents.length - 1) {
      setAllDocumentsCompleted(true);
    } else {
      setIndex((prevIndex) => (prevIndex < documents.length - 1 ? prevIndex + 1 : prevIndex));
    }
    updateStatus(index + 1);
  };

  const prevDocument = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    updateStatus(index - 1);
  };

  const readDocument = (index) => {
    setDocuments((prevDocs) =>
      prevDocs.map((doc, idx) => {
        if (idx === index) {
          return { ...doc, read: true };
        }
        return doc;
      })
    );
  };

  const scrollableDocument = () => {
    if (readingRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = readingRef.current;

      const nonScrollable = scrollHeight <= clientHeight
      if (nonScrollable) {
        readDocument(index)
      }

      const reachedBottom = scrollTop + clientHeight >= scrollHeight - 10; // adding a small buffer
      if (reachedBottom) {
        readDocument(index);
      }
    }
  };

  const onReading = useCallback(() => {
    scrollableDocument();
  }, [index]);

  useEffect(() => {
    readingRef.current.scrollTop = 0
    scrollableDocument()
  }, [index]);

  useEffect(() => {
    if (readingRef.current) {
      readingRef.current.scrollTop = 0;
    }
    updateStatus(index);
  }, [index]);

  return {
    documents,
    readingRef,
    length: documents.length,
    currentDocument: documents[index],
    allDocumentsCompleted: allDocumentsCompleted,
    allDocumentsRead: documents.every((doc) => doc.read),
    nextDocument,
    prevDocument,
    readDocument,
    onReading,
  };
};

export default useDocuments;