import React from "react";
import clsx from "../utils/clsx";

const Background = ({ children }) => {
  return (
    <div id="background" className={clsx(
      "fixed inset-0 z-50 overflow-y-auto",
      "bg-gray-500 bg-opacity-75",
      "w-screen",
    )}>
      {children}
    </div>
  )
}

export default Background;