import { useState, useEffect } from "react";
import axios from 'axios';

const useAccount = (accountId, apiKey) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvent = async () => {
      let endpoint = `https://${accountId}.orionexus.io/sdk/accounts`;

      try {
        const response = await axios.get(endpoint, {
          headers: {
            'Token': apiKey,
          }
        });
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (accountId) {
      fetchEvent();
    }
  }, [accountId, apiKey]);

  return { data, error, loading };
}

export default useAccount;
