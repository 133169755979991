import { useEffect, createContext, useContext, useState } from "react";
import { useParams, Link } from "react-router-dom";
import useEvent from "../hooks/useEvent";
import useAccount from "../hooks/useAccount";
import useDocuments from "../hooks/useDocuments";
import Button from "./Button";
import clsx from "../utils/clsx";
import DropDownButton from "./DropDownButton";
import { ArrowDownIcon, ArrowDownOnSquareIcon, ChevronDoubleDownIcon, InformationCircleIcon, LifebuoyIcon, PaperAirplaneIcon, PrinterIcon, UserIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Signed from "./Signed";
import Canceled from "./Canceled";
import Status from "./Status";
import useContainer from "../hooks/useContainer";
import useGeolocation from "../hooks/useGeolocation";
import Loading from "./Loading";
import Modal from "./Modal";
import useModal from "../hooks/useModal";
import ButtonGroup from "./ButtonGroup";
import Input from "./Input";
import Auth from "./Auth";

const EventContext = createContext();

const Documents = ({ context }) => {
  const { setSignButton } = useContext(EventContext)
  const documents = useDocuments(context);

  useEffect(() => {
    if (documents.allDocumentsRead) {
      setSignButton(true)
    }
  }, [documents])

  return (
    <div className="flex flex-col flex-grow overflow-hidden bg-white">
      <div
        ref={documents.readingRef}
        onScroll={documents.onReading}
        className="flex flex-col flex-grow overflow-auto p-6"
      >
        <div
          dangerouslySetInnerHTML={{ __html: documents.currentDocument.context.version.content }}
          id="editor-view-mode-signature"
          className="m-3"
        ></div>
      </div>
      <nav className="relative md:flex md:space-y-0 space-y-3 gap-3 px-6 py-3 border-t items-center justify-between">
        <div className="flex flex-auto gap-1.5">
          <div className="flex-auto flex gap-1.5 items-center truncate">
            <div className="text-xs font-bold">
              {documents.currentDocument.context.document.title}
            </div>
            <div className="flex gap-1 items-center py-1">
              <span className="text-xs text-gray-500">
                Version {documents.currentDocument.context.version.number}
              </span>
            </div>
          </div>
          <div className="flex-shrink flex items-center">
            <div className="text-xs text-gray-700 whitespace-nowrap">
              Document <span className="font-medium">{documents.currentDocument.index + 1}</span> of{' '}
              <span className="font-medium">{documents.length}</span>
            </div>
          </div>
        </div>
        {documents.length > 1 &&
          <div className="flex-initial flex gap-3 items-center">
            <Button
              text="Previous"
              size="xs"
              onClick={documents.prevDocument}
              disabled={documents.currentDocument.index === 0}
              className="max-md:w-full justify-center"
            />
            <Button
              text="Next"
              size="xs"
              onClick={documents.nextDocument}
              disabled={!documents.currentDocument.read || documents.currentDocument.index === documents.length - 1}
              className="max-md:w-full justify-center"
            />
          </div>
        }
      </nav>
    </div>
  );
};

const Title = ({ container }) => {
  return (
    <div className="">
      <h1 className="text-3xl font-bold">{container.title}</h1>
    </div>
  )
}

const Subtitle = ({ account }) => {
  return (
    <div className="">
      <h2 className="text-sm text-gray-500 leading-7">{account.name} has invited you to sign these documents.</h2>
    </div>
  )
}

const Header = ({ container, account }) => {
  return (
    <div className="p-6 bg-white">
      <Title container={container} />
      <Subtitle account={account} />
    </div>
  )
}

const Toolbar = () => {
  const { signButton, handleSign } = useContext(EventContext)
  const delegateModal = useModal();
  const postponeModal = useModal();
  const declineModal = useModal();

  return (
    <div className="bg-slate-100 py-3 px-6 flex flex-row-reverse gap-3 items-center">
      <Button
        text="Secure Sign"
        size="xs"
        onClick={handleSign}
        disabled={!signButton}
      />
      <DropDownButton
        text="Actions"
        size="xs"
        items={[
          [
            {
              text: "Delegate",
              onClick: delegateModal.openModal,
              icon: UserIcon,
            },
            {
              text: "Postpone",
              onClick: postponeModal.openModal,
              icon: PaperAirplaneIcon,
            },
            {
              text: "Decline",
              onClick: declineModal.openModal,
              icon: XCircleIcon,
            }
          ],
          [
            {
              text: "Print",
              onClick: () => console.log("action 3"),
              icon: PrinterIcon,
            },
            {
              text: "Download",
              onClick: () => console.log("action 4"),
              icon: ArrowDownOnSquareIcon,
            },
          ],
          [
            {
              text: "Support",
              onClick: () => console.log("action 3"),
              icon: LifebuoyIcon,
            },
            {
              text: "About",
              onClick: () => console.log("action 4"),
              icon: InformationCircleIcon,
            },
          ]
        ]}
      />
      <Modal
        title="Delegate event"
        description="Assign the signature to another person."
        isOpen={delegateModal.isOpen}
        closeModal={delegateModal.closeModal}
        buttons={{
          cancel: {
            text: "Cancel",
            onClick: () => delegateModal.closeModal()
          },
          action: {
            text: "Delegate",
            onClick: () => delegateModal.closeModal()
          },
        }}
      >
        <p className="text-xs">Delegate action content...</p>
      </Modal>
      <Modal
        title="Postpone event"
        description="Complete the event signature at another time."
        isOpen={postponeModal.isOpen}
        closeModal={postponeModal.closeModal}
        buttons={{
          cancel: {
            text: "Cancel",
            onClick: () => postponeModal.closeModal()
          },
          action: {
            text: "Postpone",
            onClick: () => postponeModal.closeModal()
          },
        }}
      >
        <p className="text-xs">Postpone action content...</p>
      </Modal>
      <Modal
        title="Decline event"
        description="Decline signing the event."
        isOpen={declineModal.isOpen}
        closeModal={declineModal.closeModal}
        buttons={{
          cancel: {
            text: "Cancel",
            onClick: () => declineModal.closeModal()
          },
          action: {
            text: "Delegate",
            onClick: () => declineModal.closeModal()
          },
        }}
      >
        <p className="text-xs">Decline action content...</p>
      </Modal>
    </div>
  )
}

const Event = () => {
  const apiKey = '0HNWt_0oBTdRgKFCZY8Eq8lGZAUDaLxGcMCzf3SEGUNummzrAA1rv0ihUOFw84C3ZAbZoK59Hxgnd-xe0I6WwA';
  const { accountId, eventId } = useParams();
  const [signButton, setSignButton] = useState(false);
  const geolocation = useGeolocation();
  const event = useEvent(accountId, apiKey, eventId);
  const container = useContainer(accountId, apiKey, event.data?.container?.id)
  const account = useAccount(accountId, apiKey)
  const authModal = useModal();

  if (
    event.loading ||
    account.loading ||
    container.loading
  ) {
    return <Loading />
  }

  if (
    event.error ||
    account.error ||
    container.error
  ) {
    event.error && console.log("Event Errors: ", event.error)
    account.error && console.log("Account Errors: ", account.error)
    container.error && console.log("Container Errors: ", container.error)
  }

  if (event.data.status === "sent") {
    event.updateEventStatus("opened")
  }

  if (
    event.data.status === "canceled" ||
    event.data.status === "signed" ||
    event.data.status === "review"
  ) {
    return <Status status={event.data.status} />
  }

  const handleSign = () => {
    authModal.openModal()
  }

  return (
    <EventContext.Provider value={{ event, account, signButton, handleSign, setSignButton, geolocation }}>
      <div className="flex flex-col h-screen overflow-hidden">
        <Toolbar />
        <Header container={event.data.container} account={account.data} />
        <Documents context={event.data.context} />
        <Auth modal={authModal} sign={() => { event.sign(geolocation) }} />
      </div>
    </EventContext.Provider>
  );
}

export default Event;
