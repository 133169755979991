import { useState, useEffect } from "react";
import axios from 'axios';

const useContainer = (accountId, apiKey, containerId) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContainer = async () => {
      let endpoint = `https://${accountId}.orionexus.io/sdk/containers/${containerId}`;
      
      try {
        const response = await axios.get(endpoint, {
          headers: {
            'Token': apiKey,
          }
        });
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (accountId && containerId) {
      fetchContainer();
    }
  }, [accountId, apiKey, containerId]);

  return { data, error, loading };
}

export default useContainer;
